'use client';

import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';

export type DialogCloseProps = {
  onClose: (event: any) => void;
  hideCloseButton?: boolean;
};

export const DialogClose = ({ onClose, hideCloseButton }: DialogCloseProps) => {
  const translate = useSharedComponentsTranslate();

  return (
    <div slot="close">
      {!hideCloseButton ? (
        <IconButton
          aria-label={translate('ReactOverlay.labels.close')}
          iconName="navigation-close"
          onClick={onClose}
          variant="clear"
          data-bleed="true"
          className="fade-in"
        />
      ) : null}
    </div>
  );
};
